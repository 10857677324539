@font-face {
  font-family: 'Atkinson';
  src: url('./fonts/AtkinsonHyperlegibleMono-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.App {
  font-family: 'Atkinson';
  text-align: center;
  z-index: 1;
  position: relative;
  height: 100vh; /* Ensure it takes the full height */
  overflow: hidden; /* Hide default scroll bars */
}

.background {
  background: radial-gradient(at center,#333333,#141414);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.projects-background {
  background: radial-gradient(at top left, #041E42, rgb(0, 0, 0));
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.App-header {
  display: flex;
  align-items: center;
  font-size: calc(5px + 1.5vmin);
  letter-spacing: -1.0px;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px 25px;
  z-index: 1000; /* Highest z-index */
  background-color: #0f0f0f;
  border-bottom: 5px solid black;
}

.HeaderGroup {
  display: flex;
  align-items: center;
}

/* Profile Picture */
.ProfileCircle {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border: 2px solid black;
  margin-right: 25px;
}

.LeftSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.Name, .Title {
  margin: 0;
}

.RightSection {
  display: flex;
  align-items: center;
  list-style: none;
  padding-left: 50px;
  margin: 0 50px; /* Adjust spacing as needed */
}

.RightSectionItem {
  position: relative;
  margin: 0 50px;
  cursor: pointer;
  padding: 5px;
}

.RightSectionItem a {
  color: white;
  text-decoration: none;
}

.RightSectionItem a:visited {
  color: white;
  text-decoration: none;
}

.RightSectionItem::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -5px;
  left: 0;
  background-color: white;
  transition: width 0.3s ease;
}

.RightSectionItem:hover::after {
  width: 100%;
}

/* Icon buttons on the far right side */
.IconButtons {
  margin-left: auto; /* pushes this group all the way to the right */
  margin-right: 100px;
  display: flex;
  align-items: center;
}

.IconButton {
  display: inline-block;
  margin-left: 20px;
  position: relative;
  padding: 5px;
  cursor: pointer;
}

.IconButton img {
  width: 40px; /* Adjust size as desired */
  height: 40px;
  filter: brightness(1);
  transition: filter 0.3s ease;
}

.IconButton:hover img {
  filter: brightness(1.5); /* Highlight on hover */
}

canvas {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100vw;
  min-height: 100vh;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.Content {
  position: absolute;
  top: 0px; /* Adjust based on header height */
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: scroll; /* Makes it scrollable */
  padding: 10px;
  z-index: 1;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.Content::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}

.ScrollContainer {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center horizontally */
  justify-content: center; /* Center vertically */
}
