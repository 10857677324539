.hero-section {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
    font-family: "Atkinson";
    background: transparent;
  }
  
  .hero-header {
    font-size: 2.5rem;
    font-weight: bold;
    color: black;
    text-align: center;
    margin-bottom: 40px;
  }
  
  /* Wrap the content in a ratio-preserving container */
  .hero-content {
    position: relative;
    width: 100%;
    /* Set the aspect ratio: if image is 1200x800, ratio = 800/1200 * 100% ≈ 66.67% */
    padding-bottom: 46.67%;
    
    border: 2px solid black;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
  }
  