.rounded-container {
    background-color: #0f0f0f;
    border: 2px solid black;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
    
    /* Use custom properties for dynamic styles */
    height: var(--container-height, auto);
    width: var(--container-width, auto);
    border-radius: var(--container-radius, 10px);
  }
  
  .AppTitle {
    letter-spacing: -1.5px;
  }
  
  .Description{
    letter-spacing: -0.5px;
  }