/* src/pages/LandingPage.css */

@font-face {
  font-family: 'Atkinson';
  src: url('../fonts/AtkinsonHyperlegibleMono-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* General Styles */
.Content {
    font-family: "Atkinson";
    color: #333333;
    margin: 0 auto;
    max-width: 1200px;
    padding: 20px;
  }

  /* This adds padding between each sub-element of Content */
  .Content > * {
    margin-bottom: 40px;
  }
  
  /* Hero Section */
  .hero-section {
    text-align: center;
    padding: 60px 20px;
    background-color: transparent;
  }
  
  .hero-section .profile-picture {
    width: 150px;
    border-radius: 50%;
    margin-bottom: 20px;
  }
  
  .hero-section h2 {
    font-size: 2.5em;
    margin-bottom: 10px;
  }
  
  .hero-section p {
    font-size: 1.2em;
    max-width: 600px;
    margin: 0 auto;
  }
  
  /* About Section */
  .about-section {
    padding: 40px 20px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .about-section h2 {
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  .about-section p {
    font-size: 1.1em;
    line-height: 1.6;
  }
  
/* Skills and Experience Section */
  .skills-experience-section {
    background-color: white;
    border: 2px solid black;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
  }

  .skills-experience-section h2 {
    font-size: 32px;
    margin-bottom: 30px;
  }
  
  .skills-experience-section h3 {
    font-size: 24px;
    margin-top: 30px;
    margin-bottom: 15px;
    border-bottom: 2px solid #ccc;
    padding-bottom: 5px;
  }
  
  .skills-experience-section h4 {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #333333;
  }
  
  .skills-experience-section p {
    font-size: 16px;
    line-height: 1.6;
  }
  
  .skills-experience-section .skills-list {
    background-color: #f7f7f7;
    padding: 15px;
    border-radius: 5px;
  }
  
  .coursework-list {
    column-count: 2;
    column-gap: 40px;
  }
  
  .coursework-list p {
    margin: 0 0 10px;
  }  
  
  /* Projects Overview */
  .projects-overview {
    border: 2px solid black;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
  }
  
  .projects-overview h2 {
    font-size: 2em;
  }
  
  .projects-overview ul {
    list-style-type: none;
    padding: 0;
  }
  
  .projects-overview li {
    margin-bottom: 10px;
  }
  
  .projects-overview a {
    color: #007bff;
    text-decoration: none;
  }
  
  .projects-overview a:hover {
    text-decoration: underline;
  }
  
  /* Projects Overview */
  .projects-overview {
    background-color: white;
  }
  .projects-overview h2 {
    font-size: 2em;
    margin-bottom: 20px;
  }

  /* Projects Button */
  .projects-button {
    font-family: "Atkinson";
    background-color: white;
    color: black;
    font-weight: bold;
    border: 2px solid black;
    padding: 10px 20px;
    cursor: pointer;
    transition: transform 0.2s;

    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
  }
  .projects-button:hover {
    transform: scale(1.05);
  }

  /* Horizontal Scroll Container */
  .project-scroll-container {
    width: 100%;
    overflow: hidden; /* Hide the scrollbars */
    position: relative;
    margin-top: 40px;
  }

  /* Scrolling Content */
  .scrolling-content {
    display: inline-flex;
    white-space: nowrap;
    animation: scroll 60s linear infinite;
  }

  /* Keyframes for continuous scrolling */
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%); /* Move by half since we duplicated the content */
    }
  }

  /* Project Sample Items */
  .project-sample {
    width: 350px; /* Fixed width to ensure only ~3-4 show at once on a standard screen */
    display: inline-block;
    text-align: center;
    margin: 0 20px;
    box-sizing: border-box;
  }

  .project-sample img {
    width: 50%;
    height: auto;
    display: block;
    margin: 0 auto;
    border-radius: 10px;
    border: 1px solid black;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
  }

  /* Project Title */
  .project-title {
    color: black;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
    font-size: 1.1em;
  }
  
  /* Contact Section */
  .contact-section {
    padding: 40px 40px;
    max-width: 600px;
    margin: 0 auto;

    border: 2px solid black;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
  }
  
  .contact-section h2 {
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  .contact-form .form-group {
    margin-bottom: 20px;
  }
  
  .contact-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .contact-form label span {
    color: red;
  }
  
  .contact-form input,
  .contact-form textarea {
    width: 100%;
    padding: 10px;
    font-size: 1em;
    box-sizing: border-box;
  }
  
  .contact-form button {
    font-family: "Atkinson";
    display: inline-block;
    padding: 12px 25px;
    background-color: #007bff;
    color: #fff;
    font-size: 1em;
    border: none;
    cursor: pointer;

    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
  }
  
  .contact-form button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
  }
  
  /* Status Message */
  .status-message {
    margin-top: 10px;
    font-weight: bold;
    font-size: 1em;
  }
  
  .status-success {
    color: green;
  }
  
  .status-error {
    color: red;
  }
  