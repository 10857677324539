@font-face {
    font-family: 'Atkinson';
    src: url('../../fonts/AtkinsonHyperlegibleMono-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

.AppStoreButton {
    font-family: 'Atkinson';
    display: inline-block;
    padding: 10px 20px;
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    font-weight: bold;
    text-decoration: none;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
}

.AppStoreButton:hover {
    transform: scale(1.05);
}
